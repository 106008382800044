<!-- <div class="container-lg" style="width: 100%;padding-right: 15px;padding-left: 15px;margin-right: auto;margin-left: auto;"> -->
<div class="main-content" style="background-color: #FFF; border-radius: 5px;">
    <div class="container-lg">
<h1 mat-dialog-title>Change Password</h1>
<form [formGroup]="formGroup" (submit)="submitForm()">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-3">
                <mat-form-field>
                    <mat-label>Old Password</mat-label>
                    <input matInput formControlName="old_password" type="password" required>
                    <mat-error>
                        <control-messages [control]="formGroup.get('old_password')">
                        </control-messages>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-5">
                <mat-form-field>
                  <mat-label>Password</mat-label>
                  <input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'" required>
                  <button
                    mat-icon-button
                    matSuffix
                    type="button"
                    (click)="hidePassword = !hidePassword"
                    [attr.aria-label]="hidePassword ? 'Show password' : 'Hide password'"
                    >
                    <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                    </button>
                  <mat-error *ngIf="formGroup.get('password')?.hasError('required')">
                    Password is required.
                  </mat-error>
                  <mat-error *ngIf="formGroup.get('password')?.hasError('pattern')">
                    Password must be at least 8 characters long, include an uppercase letter, a number, and a special character.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field>
                  <mat-label>Confirm Password</mat-label>
                  <input matInput formControlName="confirm_password" [type]="hideConfirmPassword ? 'password' : 'text'" required>
                  <button
                  mat-icon-button
                  matSuffix
                  type="button"
                  (click)="hideConfirmPassword = !hideConfirmPassword"
                  [attr.aria-label]="hideConfirmPassword ? 'Show confirm password' : 'Hide confirm password'"
                >
                  <mat-icon>{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
                  <mat-error *ngIf="formGroup.get('confirm_password')?.hasError('required')">
                    Confirm password is required.
                  </mat-error>
                  <mat-error *ngIf="formGroup.get('confirm_password')?.hasError('mustMatch')">
                    Passwords must match.
                  </mat-error>
                </mat-form-field>
              </div>
        </div>
    </div>
<br>
    <div class="row">
        <div class="col-md-6">
            <button mat-raised-button color="accent" [mat-dialog-close]="false">Cancel</button>
        </div>
        <div class="col-md-6">
            <button mat-raised-button small color="primary" [disabled]="formGroup.invalid">Save</button>
        </div>
    </div>
</form>
</div>
</div>