import { Component, OnInit, Inject,ElementRef, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'src/app/core/services/toastr.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/core/http/http.service';
import { API_ROUTES } from 'src/app/core/http/api-routes';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  hidePassword = true;
  hideConfirmPassword = true;
  formGroup: FormGroup
  constructor(
    private httpApi: HttpService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private auth: AuthService,
    // public dialogRef: MatDialogRef<ChangePasswordComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.formGroup = this.fb.group({
      old_password: ["", [Validators.required, Validators.minLength(6)]],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,}$'
          )
        ]
      ],
      confirm_password: ['', Validators.required]
    },
      { validator: CustomValidators.MatchingPasswords }
    );
  }
  async submitForm() {
    console.log(this.formGroup.value);
    
    try {
      let result = await this.httpApi.update(API_ROUTES.CHANGE_USER_PASSWORD, this.formGroup.value).toPromise();
      this.toastr.success("Password successfully updated");
      this.formGroup.reset()
      this.auth.logOut();
      this.router.navigate(['/users/login']);
      this.ngOnInit();
    } catch (error) {
      // console.log(error);
      this.toastr.error(error.message)
    }

  }
}
export class CustomValidators {

  static MatchingPasswords(control: AbstractControl) {
    const password = control.get('password').value;
    const confirm_password = control.get('confirm_password').value;
    const currentErrors = control.get('confirm_password').errors
    const confirmControl = control.get('confirm_password')

    if (compare(password, confirm_password)) {
      confirmControl.setErrors({ ...currentErrors, not_matching: true });
    } else {
      confirmControl.setErrors(currentErrors)
    }
  }
}

function compare(password: string, confirm_password: string) {
  return password !== confirm_password && confirm_password !== ''
}